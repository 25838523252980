import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In categories like “MTB Singletrack” you should only show singletracks
publicly. To also provide the information of the whole tour (with the climb,
descend, and the ride back to the start), you can upload the whole loop as
a “Hidden” trail.`}</p>
    <Image src="pro/select-hidden.jpg" mdxType="Image" />
    <p>{`As soon as you have saved this track, you see it’s unique URL in the browser:`}</p>
    <Image src="pro/portal-activity-url.jpg" mdxType="Image" />
    <p>{`Copy “`}<a parentName="p" {...{
        "href": "https://trailguide.net/3887%E2%80%9D"
      }}>{`https://trailguide.net/3887”`}</a>{` and paste it into the description of the
main activity. Every “hidden” trail is not visible publicly, but can be
accessed through its URL. Your guests get to the information if they need,
but the public map stays clean and easy to read. With many public loops it
just turns in to Spaghetti and becomes unreadable.`}</p>
    <Image src="pro/how-to-find.jpg" mdxType="Image" />
    <br />
    <br />
    <Link className="float-left" to="/pro/description" mdxType="Link">
  ← Trail description and details
    </Link>
    <Link className="float-right" to="/pro/picturesvideos" mdxType="Link">
  Pictures and videos →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      